import React from 'react'
import ProfileElectricity from '../templates/profileElectricity'


const ProfileElectricityController = props => {

    return (
        <ProfileElectricity />
    )
}
export default ProfileElectricityController