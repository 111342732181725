import React from 'react'
import Profile from "../organisms/profile"

const ProfileController = props => {

    return (
        <Profile

        />
    )
}
export default ProfileController