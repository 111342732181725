import React from 'react'
import ProfileSettings from "../templates/profileSettings"


const ProfileSettingsController = props => {

    return (
        <ProfileSettings />
    )
}
export default ProfileSettingsController